textarea input:focus {
  /* border: none; */
  outline: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
   -webkit-transition-delay: 9999s;
   transition-delay: 9999s;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react_time_range__time_range_container {
  width: 100%!important;
  padding: 0 4px!important;
  height: 30px !important;
}
.react_time_range__track {
  height: 20px!important;
  margin-top: 15px!important;
}

.react_time_range__rail__outer {
  margin-top: 15px !important;
}

.react_time_range__handle_container {
  height: 20px!important;
  margin-top: 15px!important;
  z-index: 10!important;
}
.react_time_range__handle_marker {
  height: 5px!important;
  background-color: #9E77ED!important;
  z-index: 10!important;
}

.react_time_range__handle_wrapper {
  height: 20px!important;
  margin-top: 15px!important;
  z-index: 15!important;

  opacity: 0!important;
}

.react_time_range__track{
  background-color: #9E77ED!important;
}

.react_time_range__rail__inner{
  background-color: #D6BBFB!important;
}