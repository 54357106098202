/* FilterDrawer.css */
.filter-drawer {
  position: fixed;
  right: 0px;
  background-color: #fff;
  overflow: hidden;
  transition: height 0.3s ease;
}

.content {
  padding: 16px;
}

.open {
  height: '100%';
}
