.react_time_range__time_range_container {
    width: 100% !important;
    height: 40px !important;
    position: relative !important;
    padding: 0px !important;
  }
  
  .react_time_range__handle_container {
    height: 24px !important;
    width: 12px !important;
    z-index: 10 !important;
    border: 2px solid #7f56d9 !important;
    border-radius: 4px !important;
    top: 14px !important;
  }
  .react_time_range__handle_marker {
    height: 0px !important;
    background-color: #9e77ed !important;
    z-index: 10 !important;
  }
  
  .react_time_range__handle_wrapper {
    height: 24px !important;
    width: 12px !important;
    top: 16px !important;
    opacity: 0 !important;
  }
  
  .react_time_range__track {
    background-color: #7f56d9 !important;
    height: 8px !important;
    top: 28px !important;
    margin: 0px !important;
  }
  
  .react_time_range__rail__inner {
    height: 8px !important;
    background-color: #e4e7ec !important;
    top: 28px !important;
    border-radius: 4px !important;
    border: 0px !important;
  }
  
  .react_time_range__tick_marker__large {
    height: 8px !important;
    background-color: #d6bbfb !important;
    opacity: 0 !important;
  }
  
  .react_time_range__tick_label {
    font-weight: 400 !important;
    font-size: 12px !important;
    color: #667085 !important;
    margin-top: 0px !important;
    top: -2px !important;
  }
  
  .tooltip {
    display: none !important;
  }
  